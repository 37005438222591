import { Lightning } from '@lightningjs/sdk'
import BaseComponent from '../../../components/base'
import {
  ALIGN_ITEMS,
  COLORS,
  CREDITS_FLOW_MODAL_TYPE,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../constants'
import {
  restrictedAuthorized,
  restrictedUnauthorized,
  unrestrictedAuthorized,
  unrestrictedUnauthorized,
} from './templates'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'

interface ICreditsFlowModalData {
  stream: any,
  program: any,
  type: CREDITS_FLOW_MODAL_TYPE,
  resolveCallback: (recheck?: boolean) => void,
  rejectCallback: () => void,
  shouldKeepFocus?: boolean,
}

const templateMap = {
  [CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_UNAUTHORIZED]: unrestrictedUnauthorized,
  [CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_AUTHORIZED]: unrestrictedAuthorized,
  [CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED]: restrictedUnauthorized,
  [CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED]: restrictedAuthorized,
}

export default class CreditsFlowModal extends BaseComponent {
  private title = ''
  private _type?: CREDITS_FLOW_MODAL_TYPE
  private _focusIndex = 0
  private _focusMap: any[] = []
  private _modalData!: ICreditsFlowModalData
  private _content!: any

  static override _template() {
    return {
      rect: true,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      color: COLORS.black,
      Title: {
        y: 219,
        x: (w: number) => w / 2,
        mountX: 0.5,
        text: {
          textAlign: TEXT_ALIGN.center,
          fontSize: 56,
          fontFace: FONT_FACE.light,
          textColor: COLORS.white,
          verticalAlign: VERTICAL_ALIGN.middle,
          lineHeight: 70,
          letterSpacing: -0.25,
          text: this.bindProp('title'),
          wordWrap: true,
          wordWrapWidth: 1051,
        },
      },
      Content: {
        w: 1439,
        h: 478,
        x: (w: number) => w / 2,
        mountX: 0.5,
        flex: {
          direction: FLEX_DIRECTION.row,
          justifyContent: JUSTIFY_CONTENT.spaceBetween,
          alignItems: ALIGN_ITEMS.center,
        },
        flexItem: false,
        y: 402,
      },
    }
  }

  set modalData(data: ICreditsFlowModalData) {
    this._modalData = data
    this.setModalType()
  }

  private setModalType() {
    if (this._type === this._modalData.type) return
    if (this._content?.childList?.length)
      this._content.childList.clear()
    this._type = this._modalData.type
    this._patchTemplate()
  }

  override _init() {
    const title = this.tag('Title')
    if (title) {
      title.on('txLoaded', (texture: Lightning.textures.TextTexture) => {
        const linesLength = texture.source?.renderInfo?.lines.length || 0
        const textureGain = (title.text.lineHeight - title.text.fontSize) / 2
        const y = linesLength > 1 ? 175 - textureGain : 219 - textureGain
        if (y !== title.y) title.y = y
      })
    }
    this._content = this.tag('Content')
  }

  override _active() {
    if (!this._modalData.shouldKeepFocus) {
      this._focusIndex = 0
    }
  }

  private _patchTemplate(): void {
    if (!this._type) return
    const templateFunc = templateMap[this._type]
    if (!templateFunc) return
    const { title, template } = templateFunc(
      this._modalData.stream,
      this._modalData.program,
      (recheck?: boolean) => {
        this._modalData.resolveCallback?.(recheck)
        ModalManager.close()
      },
      () => {
        ModalManager.open(ModalTypes.CREDITS_FLOW_MODAL, {
          ...this._modalData,
          shouldKeepFocus: true,
        }, false)
      })
    this.title = title
    this._content.patch(template)
    this._focusMap = []
    this._content.childList.forEach((element: any) => {
      if (!element.skipFocus) {
        this._focusMap.push(element)
      }
    })
    this._focusIndex = 0
    this._refocus()
  }

  override _setTranslation() {
    this._patchTemplate()
  }

  override _handleKey(e: KeyboardEvent): boolean {
    return true
  }

  override _handleBack(): void {
    this._modalData.rejectCallback()
  }

  override _handleLeft(): void {
    if (this._focusIndex > 0) {
      this._focusIndex--
    }
  }

  override _handleRight(): void {
    if (this._focusIndex < this._focusMap.length - 1) {
      this._focusIndex++
    }
  }

  override _getFocused() {
    return this._focusMap[this._focusIndex]
  }
}
